/* src/EbookComponent.css */

:root {
  --primary-color: rgba(98, 59, 111, 0.8);
  --secondary-color: rgba(224, 194, 132, 0.8);
}

.ebook-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  background-color: var(--secondary-color);
}

.ebook-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  text-align: center;
  padding: 10px;
}

.ebook-cover img {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 10px;
}

.ebook-details {
  margin-top: 10px;
}

.ebook-details h2 {
  color: var(--primary-color);
  font-size: 1.5em;
  margin-bottom: 10px;
}

.ebook-details p {
  color: #333;
  font-size: 1em;
  margin-bottom: 10px;
}

.buy-button {
  background-color: var(--primary-color);
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1em;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.buy-button:hover {
  background-color: rgba(98, 59, 111, 1);
}

/* Responsive Design */
@media (max-width: 768px) {
  .ebook-details h2 {
    font-size: 1.3em;
  }

  .ebook-details p {
    font-size: 0.9em;
  }

  .buy-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}
