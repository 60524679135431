  h1 {
    text-align: center;
    color: #333;
  }
  
  .edit-blog-post form {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .edit-blog-post input[type="text"],
  .edit-blog-post textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }

  .edit-blog-post textarea {
    height: 200px;
  }
  
  .edit-blog-post input[type="text"]:focus,
  .edit-blog-post textarea:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .edit-blog-post button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .edit-blog-post button:hover {
    background-color: #0056b3;
  }
  