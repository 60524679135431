/* DashboardPosts.css */

.dashboard-posts-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-posts-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  .dashboard-posts-container ul {
    list-style-type: none;
    padding: 0;
  }
  
  .dashboard-posts-container li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 15px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .dashboard-posts-container h2 {
    font-size: 1.5em;
    margin-top: 0;
    color: #555;
  }
  
  .dashboard-posts-container div {
    font-size: 1em;
    color: #666;
    margin: 10px 0;
  }
  
  .dashboard-posts-container button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    margin-top: 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dashboard-posts-container button:hover {
    background-color: #0056b3;
  }
  