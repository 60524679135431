/* BookingList.css */
.booking-list-container {
    width: 80%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .booking-list {
    list-style-type: none;
    padding: 0;
  }
  
  .booking-item {
    border-bottom: 1px solid #ccc;
    padding: 10px 0;
  }
  
  .more-button {
    margin: 20px 0;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .more-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
  }
  