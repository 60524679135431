.book-details {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    font-family: Arial, sans-serif;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .book-image {
    max-width: 100%;
    max-height: 350px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
  
  .details-section {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  
  .details-section h1 {
    font-size: 24px;
    color: #423e3e;
  }
  
  .format, .price, .delivery {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .description {
    margin: 15px 0;
    line-height: 1.6;
  }
  
  .add-to-cart {
    background-color: #623b6f;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
  }
  
  .add-to-cart:hover {
    background-color: #e0c184;
  }
  
  .similar-products {
    margin-top: 40px;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
  }
  
  .product-card {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    width: calc(20% - 20px);
    transition: box-shadow 0.3s;
  }
  
  .product-card:hover {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .product-image {
    max-width: 100%;
    border-radius: 5px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .details-section {
      text-align: center;
    }
  
    .product-card {
      width: calc(45% - 20px);
    }
  }
  
  @media (max-width: 480px) {
    .product-card {
      width: 100%;
    }
  }