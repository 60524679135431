/* ProductComponent.css */

.product-card {
  background-color: #e0c184;
  border: 1px solid #623b6f;
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  margin: 1rem auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.product-card-image img {
  width: 100%;
  height: auto;
  display: block;
}

.product-card-content {
  padding: 1rem;
  text-align: center;
}

.product-card-title {
  color: #623b6f;
  font-size: 1.2rem;
  margin: 0.5rem 0;
}

.product-card-price {
  color: #623b6f;
  font-size: 1rem;
  margin: 0.5rem 0;
}

.product-card-in-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
}

.product-card-quantity {
  margin-bottom: 0.5rem;
}

.product-card-buttons {
  margin-bottom: 1rem;
  margin-top: 1em;
}

.product-card-button {
  cursor: pointer;
  color: #e0c184;
  font-size: 1.2rem;
  margin: 0 0.5rem;
  padding: 0.5rem;
  border: 1px solid #623b6f;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.product-card-button.add:hover {
  background-color: #623b6f;
  color: #e0c184;
}

.product-card-button.remove:hover {
  background-color: #623b6f;
  color: #e0c184;
}

.product-card-remove-btn {
  background-color: #ff6f6f;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  width: 100%;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.product-card-remove-btn:hover {
  background-color: #ff4a4a;
}

.product-card-add-btn {
  background-color: #623b6f;
  color: #e0c184;
  border: none;
  border-radius: 4px;
  padding: 0.75rem;
  width: 100%;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.product-card-add-btn:hover {
  background-color: #7f4a8a;
}

@media (max-width: 768px) {
  .product-card {
    max-width: 100%;
    margin: 0.5rem auto;
  }

  .product-card-title {
    font-size: 1rem;
  }

  .product-card-price {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .product-card-title {
    font-size: 0.9rem;
  }

  .product-card-price {
    font-size: 0.8rem;
  }

  .product-card-button {
    font-size: 1rem;
    padding: 0.4rem;
  }

  .product-card-add-btn {
    font-size: 0.9rem;
    padding: 0.6rem;
  }

  .product-card-remove-btn {
    font-size: 0.8rem;
    padding: 0.4rem;
  }
}
