.booking-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 800px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-form {
    margin-bottom: 20px;
  }
  
  .picker-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .date-picker,
  .time-picker {
    padding: 10px;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    width: 100%;
    max-width: 200px;
    background-color: #fff;
    transition: border 0.3s;
  }
  
  .date-picker:focus,
  .time-picker:focus {
    border-color: var(--primary-color);
    outline: none;
  }
  
  /* TimePicker specific styles */
.time-picker {
    background: #fff;
    font-size: 1rem;
    color: #333;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    height: 40px; /* Adjust height as needed */
    padding: 10px; /* Padding to ensure text is visible */
  }
  
  /* Ensure TimePicker controls are visible */
  .react-time-picker__wrapper {
    display: flex;
    align-items: center;
  }
  
  .react-time-picker__inputGroup {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .notification {
    color: red;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .react-time-picker__inputGroup__input {
    flex: 1;
    text-align: center;
    font-size: 1rem;
  }
  
  /* Button styling */
  .add-button {
    background: var(--primary-color);
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    display: block;
    width: 100%;
    margin-top: 10px;
  }
  
  .add-button:hover {
    background: var(--primary-color);
  }
  
  
  .calendar-container {
    flex-grow: 1;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 600px) {
    .picker-container {
      flex-direction: column;
      align-items: stretch;
    }
  
    .date-picker,
    .time-picker,
    .add-button {
      width: 100%;
    }
  }