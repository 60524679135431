/* .hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('../../../utils/img/Hero/hero-img.png') no-repeat center center/cover; /* Replace with your image */
    /* position: relative;
    overflow: hidden;
} */ 

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}

.hero-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url('../../../utils/img/Hero/hero-img.png') no-repeat center center/cover; /* Replace with your image */
    position: relative;
    overflow: hidden;
}

.hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, rgba(98, 59, 111, 0.7), rgba(98, 59, 111, 0.4))
}

.hero-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: white;
    padding: 20px;
}

.hero-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    text-transform: uppercase;
}

.hero-content p {
    font-size: 1.5rem;
    margin-bottom: 30px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.cta-button {
    background: linear-gradient(90deg, #e0c184, #623b6f);
    color: white;
    border: none;
    border-radius: 5px;
    padding: 12px 20px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background 0.3s, transform 0.3s;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border: 1px solid #311939;
}

.cta-button:hover {
    transform: scale(1.05);
}

/* Responsive design */
@media (max-width: 768px) {
    .hero-content h1 {
        font-size: 2rem;
    }
    
    .hero-content p {
        font-size: 1.2rem;
    }

    .cta-button {
        padding: 10px 15px;
    }
    .hero-content {
        padding: 10px;
    }
}