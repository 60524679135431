.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
}

.not-found-icon {
    font-size: 50px;
    color: #007BFF;
    margin-bottom: 20px;
}

.not-found-code {
    font-size: 80px;
    font-weight: bold;
    margin: 0;
}

.not-found-title {
    font-size: 32px;
    font-weight: bold;
    margin: 10px 0;
}

.not-found-message {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
}

.back-home-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-home-button:hover {
    background-color: var(--primary-color);
}