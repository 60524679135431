.contact-form-container {
    background-color: #e0c184;
    color: #623b6f;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #623b6f;
    border-radius: 4px;
    background-color: #fff;
    color: #623b6f;
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #e0c184;
    outline: none;
  }
  
  .submit-button {
    background-color: #623b6f;
    color: #e0c184;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #4a2c4e;
  }
  
  .submission-message {
    text-align: center;
    font-size: 18px;
    color: #4a2c4e;
  }
  