.about-container {
    font-family: 'Arial', sans-serif;
    color: #333;
    padding: 2rem;
    background-color: #f9f9f9;
  }
  
  .about-us-section {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .about-us-item {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;
    gap: 2rem; /* Space between image and text */
  }
  
  .about-us-item.reverse {
    flex-direction: row-reverse;
  }
  
  .about-us-desc {
    flex: 1;
    padding: 1.5rem;
    background-color: #e0c184;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-us-img {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .about-us-img img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    color: #623b6f;
    margin-bottom: 1rem;
    font-size: 2rem;.about-container {
        font-family: 'Roboto', sans-serif;
        color: #333;
        padding: 4rem 2rem;
        background-color: #f0f0f0;
      }
      
      .about-us-section {
        max-width: 1200px;
        margin: 0 auto;
        padding: 2rem;
      }
      
      .about-us-item {
        display: flex;
        align-items: center;
        margin-bottom: 4rem;
        gap: 2rem; /* Space between image and text */
      }
      
      .about-us-item.reverse {
        flex-direction: row-reverse;
      }
      
      .about-us-desc {
        flex: 1;
        padding: 2rem;
        background-color: #e0c184;
        border-radius: 10px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
        text-align: left;
      }
      
      .about-us-img {
        flex: 1;
        display: flex;
        justify-content: center;
      }
      
      .about-us-img img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      }
      
      h2 {
        color: #623b6f;
        margin-bottom: 1rem;
        font-size: 2.5rem;
        font-weight: 700;
      }
      
      p {
        line-height: 1.7;
        font-size: 1.125rem;
      }
      
      @media (max-width: 768px) {
        .about-us-item {
          flex-direction: column;
          gap: 1rem;
        }
        
        .about-us-desc {
          padding: 1.5rem;
        }
        
        .about-us-img img {
          width: 100%;
          max-height: 300px;
          object-fit: cover;
        }
      }
      
  }
  
  p {
    line-height: 1.8;
    font-size: 1rem;
  }
  
  @media (max-width: 768px) {
    .about-us-item {
      flex-direction: column;
      text-align: center;
    }
    
    .about-us-item.reverse {
      flex-direction: column;
    }
  }
  