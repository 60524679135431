/* General Styles */
.section {
    padding: 3rem 1.5rem;
    background-color: #f9f5f2;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Flexbox Styles */
  .flex-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 0;
    list-style: none;
    margin-top: 2rem;
  }
  
  .flex-item {
    flex: 1 1 calc(25% - 1.5rem); /* Adjusts items to take up 25% of the width minus gap space */
    box-sizing: border-box;
  }
  
  .stats-card {
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    padding: 2rem;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .stats-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
  }
  
  .card-text {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .flex-item {
      flex: 1 1 100%; /* Takes up the full width on small screens */
    }
  }
  
  @media (min-width: 769px) {
    .flex-item {
      flex: 1 1 calc(50% - 1.5rem); /* Two items per row for medium screens */
    }
  }
  
  @media (min-width: 1200px) {
    .flex-item {
      flex: 1 1 calc(25% - 1.5rem); /* Four items per row for large screens */
    }
  }
  