
.bookstore {
    padding: 20px;
    background-color: #f8f4f1; 
  }
  
  .store-title {
    text-align: center;
    color: #623b6f;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .book-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .book {
    border: 2px solid #e0c184; 
    border-radius: 10px;
    padding: 15px;
    width: 220px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    text-align: center;
  }
  
  .book-img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .book-title {
    font-size: 18px;
    color: #623b6f;
    margin: 0 0 10px;
  }
  
  .book-price {
    font-size: 16px;
    color: #e0c184;
    margin: 5px 0 15px;
  }
  
  .add-to-cart-btn {
    background-color: #623b6f;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .add-to-cart-btn:hover {
    background-color: #4a2a54; 
  }
  