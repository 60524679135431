.analytics-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
}

.analystics-item{
    display: flex;
    justify-content: space-between;
    padding: 10px 25px;
    align-items: center;
    gap: 30px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 8px;
}

.analytics-item-lef{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    line-height: 0.8;
    margin-right: auto;
}

.analytics-item-roght{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: purple;
    color: #fff;
    padding: 8px 15px;
    border-radius: 5px;
}

.graphs-se{
    display: flex;
    gap: 30px;
    margin-top: 30px;
}

.graphs-se>*{
    flex: 1 1 50%;
}

.booking-lists{
    margin-top: 30px;
}


@media(max-width:800px){
    .analytics-items{
        flex-direction: column;
    }

    .analytics-items>*{
        width: 100%;
    }
    .graphs-se{
        flex-direction: column;
    }
}