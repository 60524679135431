/* Main component */
.bloghome-articles{
    display: flex;
    gap: 35px;
    margin: 30px 60px;
}

.section-header{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding: 25px;
    margin: 25px;
    text-align: left;
    padding-bottom: 35px;
    border-bottom: 2px solid #eee;
}

.section-header h4{
    margin-bottom: 10px;
    font-size: 22px;
}
.bloghome-articles .bloghome-container {
    flex: 0 0 70%;
    
}
.bloghome-articles .bloghome-aside {
    position: static;
    flex: 0 0 30%; /* Flex-grow, flex-shrink, and flex-basis */
    background-color: var(--secondary-color); /* For visualization */
    height: inherit;
}

.bloghome-container{
    display: flex;
    flex-direction: column;
    gap: 25px;
    
}

.bloghome-container img{
    width: 100%;
    height: 320px;
    object-fit: cover;
    transition: filter 0.3s ease;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.bloghome-container img:hover {
    filter: brightness(1.1);
}

.bloghome-container-item{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    transition: 0.2s ease-in-out;
    border-radius: 10px;
}

.bloghome-container-item:hover{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.blog-aside-items-content{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    line-height: 2;
    padding: 10px;
}


.blog-aside-item{
    display: flex;
    text-align-last: left;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 6px 20px;
    border-radius: 6px;
}

.blog-aside-item h5{
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4;
}

.blog-aside-item p{
    display: block;
    margin-top: 25px;
    color: var(--primary-color);
    font-weight: 600;
    cursor: pointer;
    text-decoration: underline;
}

.blog-aside-items h4{
    padding: 20px;
    font-size: 22px;
}


.blog-container-desc{
    padding: 15px;
    text-align-last: left;
}

.blog-container-desc h3{
    font-weight: 600;
    font-size: 18px;
}

.blog-container-desc p{
    line-height: 1.8;
    font-size: 18px;
    text-align: left;
}

.blog-container-desc .blog-read-more{
    margin-top: 30px;
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    text-transform: uppercase;
    font-weight: 500;
    cursor: pointer;
}

.read-more-icon{
    font-size: 12px;
}

@media(max-width:920px){
    .bloghome-articles{
        display: flex;
        gap: 15px;
        margin: 30px 20px;
    }
}
@media(max-width:767px){
    .bloghome-articles{
        flex-direction: column;
    }
}