.blogposts{
    margin: 30px;
}

.blog-home-header{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
}

.blog-home-header .blog-home-header-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.blog-home-header-see-more{
    margin-top: 30px;
}

.blog-home-header-see-more a {
    text-decoration: none;
    background: linear-gradient(to left, #623B6F, #7A4C8F); /* Gradient from right to left */
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    padding: 8px 15px;
    border-radius: 8px; /* Optional: Adds rounded corners */
    transition: background 0.3s ease; /* Optional: Adds smooth transition on hover */
}

.blog-home-header-see-more a:hover {
    background: linear-gradient(to left, #7A4C8F, #623B6F); /* Reverse gradient on hover */
}


.blog-home-header .blog-home-header-content h4{
    font-size: 24px;
}

.blog-container{
    display: flex;
    gap: 30px;
}

.blog-container img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.blog-container>*{
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
}

.blog-container>*:hover{
    transform: scale(1.03);
}

.blog-desc{
    padding: 15px;
    text-align: left;
}

.blog-desc h4{
    margin-bottom: 20px;
}

.blog-desc p{
    line-height: 1.8;
    text-align: left;
}
.blog-desc span{
    color: var(--primary-color);
    font-weight: 500;
}

@media(max-width:767px){
    .blog-container{
        flex-direction: column;
    }
    .blog-container img{
       height: 200px;
       object-fit: cover;
    }
    .blogposts{
        margin: 15px;
    }
}