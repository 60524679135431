/* Base styling for the header */
.header {
    background: linear-gradient(135deg, #f0f0f0, #d9d9d9); /* Soft gradient background */
    padding-bottom: 20px;
    border-bottom: 2px solid #ccc; /* Subtle border */
  }
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .header-title {
    margin-bottom: 10px;
  }
  
  .header-title h1 {
    font-size: 2.5rem;
    color: #333;
    font-weight: 700;
    margin: 0;
  }
  
  .header-breadcrumbs {
    font-size: 1rem;
    color: #555;
  }
  
  .header-breadcrumbs p {
    display: flex;
    align-items: center;
    margin: 0;
  }
  
  .breadcrumb-item {
    margin-right: 5px;
  }
  
  .breadcrumb-item a {
    color: #007bff; /* Primary color for links */
    text-decoration: none;
  }
  
  .breadcrumb-item a:hover {
    text-decoration: underline;
  }
  
  .breadcrumb-separator {
    margin: 0 5px;
    color: #888;
  }
  
  @media (max-width: 768px) {
    .header-container {
      padding: 10px;
    }
  
    .header-title h1 {
      font-size: 2rem;
    }
  
    .header-breadcrumbs {
      font-size: 0.875rem;
    }
  }
  