/* Signup.css */

.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    /* background-color: #f0f2f5; */
    padding: 20px;
  }
  
  .signup-heading {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .signup-error {
    color: #d9534f;
    margin-bottom: 15px;
  }
  
  .signup-form {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 400px;
  }
  
  .signup-label {
    display: block;
    font-size: 1rem;
    margin-bottom: 10px;
  }
  
  .signup-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .signup-input:focus {
    border-color: var(--primary-color);
    outline: none;
  }
  
  .signup-button {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    color: #fff;
    background-color: var(--primary-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .signup-button:hover {
    background-color: var(--primary-color);
  }
  