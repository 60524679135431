.product-item{
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 10px;
}

.product-item img{
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
}

.product-image{
    position: relative;
}

.product-image span{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px;
    color: grey;
    transition: 0.2s ease-in-out;
}

.product-image span:hover{
    transform: scale(1.2);
}

.product-desc{
    padding: 5px 20px;
    padding-bottom: 10px;
    text-align: center;
    line-height: 2;
}

.product-stars{
    color: goldenrod;
}

.product-btn{
    background-color: var(--primary-color);
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    padding: 8px 20px;
    cursor: pointer;
}
@media(max-width:450px){
    .product-btn{
        font-size: 16px;
        padding: 4px 10px;
    }
    .product-item img{
        object-fit: cover;
    }
}