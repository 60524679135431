.products {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .products-header {
    background-color: #e0c184;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .top-head {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #623b6f;
  }
  
  .see-more-btn {
    cursor: pointer;
    color: #623b6f;
    font-weight: bold;
    text-decoration: underline;
  }
  
  .see-more-btn:hover {
    color: #4a2c57;
  }
  
  .products-header p {
    color: #333;
    text-align: center;
    max-width: 600px;
  }
  
  .bookstore {
    padding: 20px;
  }
  
  .store-title {
    font-size: 28px;
    font-weight: bold;
    color: #623b6f;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .book-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  