.faq-container {
    background-color: #e0c184;
    color: #623b6f;
    border-radius: 8px;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .faq-list {
    list-style: none;
    padding: 0;
  }
  
  .faq-item {
    border-bottom: 1px solid #623b6f;
    padding: 15px 0;
  }
  
  .faq-question {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-question h3 {
    margin: 0;
  }
  
  .faq-toggle-icon {
    font-size: 20px;
    font-weight: bold;
  }
  
  .faq-answer {
    padding: 10px 0;
    font-size: 16px;
    line-height: 1.5;
  }
  