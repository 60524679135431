.latest-newsletter__area {
    position: relative; /* Required for positioning the pseudo-element */
    padding: 60px 0;
    margin: 40px 0;
    overflow: hidden;
    background-image: url("../../utils/img/Hero/Topology-2.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.latest-newsletter__area::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White with 80% opacity */
    z-index: 1; /* Ensures it sits above the background image */
}

.latest-newsletter__area > * {
    position: relative;
    z-index: 2; /* Ensures content sits above the overlay */
}

.latest-newsletter__content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.nl-container{
    position: relative;
}

.nl-search{
    display: flex;

}

.nl-header{
    display: flex;
    justify-content: center;
    width: 100%;
}

.nl-header .nl-header-content{
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.nl-header h2, p{
    line-height: 1.6;
    text-align: center;
}

.nl-header h2{
    font-size: 48px;
    font-weight: 800;
}

.nl-search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: var(--secondary-color);
    margin-top: 20px;
    width: 70%;
    border-radius: 8px;
}

.nl-search input{
    padding: 15px;
    outline: none;
    border: none;
    background: none;
    color: #fff;
    width: 70%;
    font-size: 18px;
}

.nl-search button{
    background: none;
    border: none;
    outline: none;
    width: fit-content;
    background-color: var(--primary-color);
    padding: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
}

.nl-search input::placeholder {
    color: #fff;
    font-weight: 500;
}

@media(max-width:767px){
    .nl-search{
        width: 95%;
        border-radius: 8px;
    }

    .nl-search input{
        padding: 6px;
        width: 60%;
        font-size: 16px;
    }

    .nl-search button{
        padding: 12px 6px;
    }

    .nl-header .nl-header-content{
        width: 95%;
    }
    .nl-header h2{
        font-size: 32px;
        font-weight: 800;
        line-height: 1.2;
        margin-bottom: 20px;
    }
}