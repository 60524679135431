.testimonial-section {
    padding: 2rem;
    background-color: #f5f5f5;
    text-align: center;
  }
  
  .testimonial-section h2 {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    color: #333;
  }
  
  .testimonial-container {
    display: flex;
    overflow-x: auto; 
    overflow-y: hidden; 
    gap: 1rem;
    padding: 1rem 0;
    justify-content: start;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    justify-content: center;
  }
  
  .testimonial-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    max-width: 300px;
    flex: 0 0 auto; 
    scroll-snap-align: start; 
    box-sizing: border-box;
    
  }
  
  .testimonial-image {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin-bottom: 1rem;
  }
  
  .testimonial-name {
    font-size: 1.2rem;
    color: #333;
  }
  
  .testimonial-feedback {
    font-size: 1rem;
    color: #666;
  }
  
  /* Hide scrollbar indicators in WebKit browsers (Safari, Chrome) */
  .testimonial-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar indicators in Firefox */
  .testimonial-container {
    scrollbar-width: none; 
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .testimonial-card {
      flex: 0 0 auto; 
      max-width: 80%; 
    }

    .testimonial-container{
    justify-content: start;
    }
  }
  
  @media (max-width: 480px) {
    .testimonial-card {
      max-width: 90%; /* Adjust size for mobile */
    }
  }
  