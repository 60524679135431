.single-post{
    margin: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.product-header{
    display: flex;
    gap: 25px;
    justify-content: space-between;
    margin-bottom: 50px;
}

.product-header img{
    width: 60%;
    height: 480px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    border-radius: 8px;
}

.product-header .product-header-desc{
    line-height: 1.8;
}

.product-header .product-header-desc h3{
    font-size: 32px;
    margin-bottom: 50px;
    text-align: left;
}

.product-header .product-header-desc p{
    text-align: left;
}

.product-header .product-header-desc .single-post-stars{
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 500;
}

.product-header .product-header-desc .single-post-stars span{
    color: gold;
    font-size: 22px;
}

.product-header .product-header-desc .single-post-main-desc{
    font-size: 24px;
    margin-bottom: 25px;
}

.product-header .product-header-desc .single-post-price{
    font-size: 28px;
    font-weight: 700;
    margin-top: 15px;
}

.cart-btn-header{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color);
    width: fit-content;
    padding: 10px 25px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #fff;
    font-weight: 500;
    margin-top: 40px;
}

.cart-btn-header input{
    outline: none;
    border: none;
    background: none !important;
    width: 95px;
    background: #aaa !important;
    padding: 15px;
    margin-right: 20px;
    font-weight: 600;
    font-size: 22px;
}

.single-post-desc{
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
    align-items: start;
    margin-bottom: 40px;
}

.single-post-desc .description-content{
    font-size: 18px;
}

.single-post-desc h4, .single-post-rel .single-post-rel-title{
    display: block;
    width: fit-content;
    margin-bottom: 25px;
    font-size: 22px;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
}

.single-post-desc h4::before, .single-post-rel .single-post-rel-title::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 60%;
    height: 4px; 
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.2)
    ); 
    border-radius: 2px;
}

.single-post-desc h4::after, .single-post-rel .single-post-rel-title::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    width: 40%;
    height: 2px;
    background-color: #007BFF;
    border-radius: 2px;
}

.single-post-desc p{
    text-align: left;
    line-height: 1.8;
}

@media(max-width:767px){
    .product-header{
        flex-direction: column-reverse;
    }
    .product-header img{
        width: 100%;
        height: 480px;
        object-fit: cover;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        border-radius: 8px;
    }
}