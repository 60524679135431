/* General Styles */
.section {
    padding: 3rem 1.5rem;
    background-color: #f9f5f2;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }

  .course .container{
    flex-direction: column;
  }
  
  /* Header Section */
  .section-subtitle {
    text-align: center;
    color: #623b6f;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .section-title {
    text-align: center;
    font-size: 2.2rem;
    color: #623b6f;
    margin: 0;
  }
  
  /* Grid List */
  .grid-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
  }
  
  /* Course Card */
  .course-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .course-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  

  .card-banner {
    position: relative;
    overflow: hidden;
  }
  
  .card-banner img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    transition: 0.3s ease-in-out;
  }

  .card-banner img:hover{
    transform: scale(1.0.5);
  }
  
  .card-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background: #623b6f;
    color: #fff;
    padding: 0.3rem 0.8rem;
    /* border-radius: 12px; */
    font-size: 0.8rem;
  }
  
  .card-content {
    padding: 1.5rem;
  }
  
  .badge {
    display: inline-block;
    background-color: #e0c184;
    color: #623b6f;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 1rem;
  }
  
  .card-title a {
    color: #623b6f;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 600;
  }
  
  .card-title a:hover {
    text-decoration: underline;
  }
  
  .rating {
    display: flex;
    gap: 25px;
    margin-bottom: 1rem;
  }
  
  .rating-stars {
    color: #f4c542;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .rating-text {
    color: #666;
    font-size: 0.9rem;
  }
  
  .card-meta {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 25px;
  }
  
  .card-meta-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: #666;
  }
  
  .icon {
    color: #e0c184;
  }
  
  /* Button */
  .btn {
    display: inline-flex;
    align-items: center;
    background-color: #623b6f;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 2rem;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .btn:hover {
    background-color: #4a2a5f;
    transform: scale(1.05);
  }
  
  .btn-text {
    margin-right: 0.5rem;
  }
  