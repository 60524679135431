/* General Styles */
.category {
    background-color: #f9f5f2; /* Light background color */
    padding: 2rem 0;
  }
  
  .category__container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .category__subtitle {
    text-align: center;
    color: #623b6f;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .category__title {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #623b6f;
  }
  
  .category__highlight {
    color: #e0c184;
  }
  
  .category__text {
    text-align: center;
    color: #333;
    margin-bottom: 2rem;
  }
  
  .category__list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .category__item {
    flex: 1 1 300px;
    max-width: 300px;
  }
  
  .category__card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .category__card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .category__icon {
    margin-bottom: 1rem;
  }
  
  .category__card-title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .category__link {
    color: #623b6f;
    text-decoration: none;
    font-weight: 600;
  }
  
  .category__link:hover {
    text-decoration: underline;
  }
  
  .category__card-text {
    color: #666;
    margin-bottom: 1rem;
  }
  
  .category__badge {
    display: inline-block;
    background-color: #e0c184;
    color: #623b6f;
    padding: 0.5rem 1rem;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
  }
  