.cart-product {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .product-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex-grow: 1;
  }
  
  .product-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }
  
  .product-quantity,
  .product-price {
    font-size: 14px;
    margin: 0;
    color: #666;
  }
  
  .remove-button {
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .remove-button:hover {
    background-color: #e60000;
  }
  
  .divider {
    display: none;
  }