:root {
  --primary-color: #623b6f;
  --secondary-color: #e0c184;
  --background-color: #f5f0e8;
  --text-color: #333;
  --accent-color: #8a5c9e;
}

/* body {
  background-color: var(--background-color);
  color: var(--text-color);
} */

.video-player-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  color: var(--text-color);
}

.video-player-content {
  flex: 1;
  padding: 2rem;
  display: flex;
  gap: 2rem;
}

.video-player-container {
  flex: 2;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-player {
  margin-bottom: 1rem;
}

.playlist {
  background-color: #f9f6f1;
  border-radius: 10px;
  padding: 1rem;
}

.playlist h3 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.playlist-item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.playlist-item:hover {
  background-color: var(--secondary-color);
  color: var(--primary-color);
}

.playlist-item .icon {
  margin-right: 0.5rem;
  color: var(--accent-color);
}

.playlist-item.locked {
  opacity: 0.5;
}

.playlist-item.completed {
  color: var(--accent-color);
  text-decoration: line-through;
}

.suggested-videos {
  flex: 1;
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.suggested-videos h3 {
  margin-bottom: 1rem;
  color: var(--primary-color);
}

.suggested-videos-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.grid-item {
  list-style-type: none;
}

@media (max-width: 768px) {
  .video-player-content {
    flex-direction: column;
  }
  
  .video-player-container,
  .suggested-videos {
    width: 100%;
  }
}