.payment-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: linear-gradient(135deg, #E0C184, #F7E7B4 );
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .payment-title {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
  }
  
  .payment-options, .mobile-options {
    margin-bottom: 20px;
  }
  
  .payment-option, .mobile-option {
    display: block;
    margin: 10px 0;
    font-size: 18px;
  }
  
  .payment-option input, .mobile-option input {
    margin-right: 10px;
  }
  
  .submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: #fff;
    background: linear-gradient(45deg, #623B6F, #9C5D82);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background: linear-gradient(45deg, #623B6F, #A76F9D);
    transform: scale(1.05);
  }
  
  .submit-button:disabled {
    background: linear-gradient(45deg, #623B6F, #9C5D82);
    cursor: not-allowed;
    opacity: 0.6;
  }
  