.product-container-admin {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .product-grid-admin {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .product-card-admin {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s;
  }
  
  .product-card-admin:hover {
    transform: translateY(-5px);
  }
  
  .product-image-admin {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .product-title-admin {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .product-desc-admin {
    color: #555;
    margin: 10px 0;
    font-size: 0.9em;
  }
  
  .product-price-admin {
    font-weight: bold;
    color: #333;
  }
  
  .loading, .error {
    font-size: 1.2em;
    color: #ff5722;
    text-align: center;
  }
  