/* General Layout */
.video-lists {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .video-lists-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1200px;
    width: 100%;
  }
  
  .video-lists-item {
    width: calc(33.333% - 20px); /* Three columns layout with gap */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    background: #fff;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .video-lists-item:hover {
    transform: scale(1.02);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .video-lists-thumb-nail {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    background: #ddd;
  }
  
  .video-lists-thumb-nail img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-lists-thumb-nail span {
    position: absolute;
    bottom: 8px;
    right: 8px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .video-lists-desc {
    padding: 15px;
  }
  
  .video-lists-desc h4 {
    font-size: 16px;
    margin: 0;
    color: #333;
  }
  
  .video-lists-tag {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #555;
    margin-top: 8px;
    text-align: left;
    align-items: start;
    justify-content: start;
  }
  
  .video-lists-tag p {
    margin: 0;
  }
  
  .video-lists-tag span {
    margin-right: 10px;
    color: #777;
  }

@media(max-width:767px){
    .video-lists-item {
        width: calc(100% - 20px);
      }
      .video-lists-container {
        flex-direction: column;
      }
}
  