.post-container{
    margin: 25px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.post-image{
    display: flex;
    justify-content: center;
    align-items: center;
}

.post-image .post-img{
    width: 90%;
    border-radius: 15px;
    height: 420px;
    object-fit: cover;
}

.post-desc{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.post-desc .post-date{
    width: 70%;
}
.post-desc .post-date p{
    font-weight: 200;
    font-size: 14px;
    text-align: left;
}

.post-title{
    width: 70%;
}

.post-title p{
    font-size: 36px;
    text-align: left;
}

.post-author-data{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    width: 70%;
    gap: 25px;
    margin-bottom: 40px;
}

.post-author-data h4{
    width: fit-content;
    text-overflow: inherit;
}

.post-author-data img{
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
}

.post-author-data p{
    font-size: 20px;
    text-align: left;
}

.post-author-data .author-name{
    font-weight: 600;
    display: block;
    font-size: 28px;
}

.post-details p{
    text-align: left;
    line-height: 1.9;
    font-size: 18px;
}
.post-details{
    position: relative;
    padding-bottom: 40px;
}

.post-details .locked-icon{
    font-size: 36px;
}

.post-details .post-sample{
    padding: 0 25px;
}
.post-details .locked-content{
    position: absolute;
    bottom: 0;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #000;
    font-size: 24px;
    font-weight: 800;
    width: 100%;
    height: 250px;
    padding: 20px;
    background: linear-gradient(
        to top, 
        rgba(255, 255, 255, 1) 0%, 
        rgba(255, 255, 255, 1) 10%, 
        rgba(255, 255, 255, 1) 20%, 
        rgba(255, 255, 255, 1) 30%, 
        rgba(255, 255, 255, 0.9) 40%, 
        rgba(255, 255, 255, 0.8) 50%, 
        rgba(255, 255, 255, 0.6) 60%, 
        rgba(255, 255, 255, 0.3) 70%, 
        rgba(255, 255, 255, 0.1) 80%, 
        rgba(255, 255, 255, 0) 90%, 
        rgba(255, 255, 255, 0) 100%
);
margin-left: 0;
margin-right: 0;
}

.share-icons{
    margin-top: 50px;
    display: flex;
    gap: 15px;
    align-self: start;
    margin-left: 25px;
    margin: 25px;
}

.share-icons>*{
    padding: 6px 30px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    color: #fff;
    cursor: pointer;
    font-size: 28px;
}

.share-icons .facebook {
    background-color: #1877f2;
}

.share-icons .twitter {
    background-color: #1da1f2;
}

.share-icons .tiktok {
    background-color: #fe2c55;
}

.share-icons .insta {
    background-color: #fccc63;
}

.share-icons .linkedin {
    background-color: #0077b5;
}

.posts-rel-items{
    display: flex;
    gap: 25px;
    /* align-items: center; */
    justify-content: center;
    margin: 40px 25px;
    flex-wrap: wrap;
}

.posts-rel-items img{
    width: 100%;
    height: 280px;
    object-fit: cover;
}

.posts-rel-items>*{
    flex: 1 1 45%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow: hidden;
    border-radius: 8px;
}

.posts-rl-item h5{
    margin-bottom: 20px;
    padding: 15px;
    font-size: 22px;
}

.posts-rl-item p{
    line-height: 1.8;
    text-align: left;
    padding: 15px;
}

.see-more-post-rel{
    text-decoration: underline;
    cursor: pointer;
    color: var(--primary-color);
    font-size: 18px;
}

@media(max-width:767px){
    .post-image .post-img{
        width: 95%;
    }
    .post-desc .post-date{
        width: 95%;
        margin-top: 10px;
    }
    .post-title{
        width: 95%;
    }
    .post-title p{
        font-size: 26px;
        text-align: left;
        line-height: 1.2;
    }

    .post-author-data{
        width: 95%;
        flex-direction: column;
        position: relative;
        padding-left: 100px;
        text-align: left;
        align-items: start;
    }

    .post-author-data img{
        position: absolute;
        left: 0;
        width: 80px;
        height: 80px;
    }

    .post-author-data p{
        font-size: 18px;
        text-align: left;
        line-height: 1.2;
    }
    
    .post-author-data .author-name{
        font-weight: 600;
        display: block;
        font-size: 22px;
    }

    .post-details .locked-content{
        height: 350px;
        font-size: 18px;
        font-weight: 800;
    }

    .post-details .locked-icon{
        font-size: 28px;
    }

    .share-icons>*{
        padding: 6px 10px;
        font-size: 18px;
    }
    
    
}

@media(max-width:600px){
    .posts-rel-items{
        flex-direction: column;
    }
}
@media(max-width:450px){
    .post-details .locked-content{
        height: 450px;
    }
}