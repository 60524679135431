/* src/BlogEditor.css */
.blog-editor {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-editor h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .savev-post-btn {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: var(--primary-color);
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .savev-post-btn:disabled {
    background-color: #ccc;
  }
  
  @media (max-width: 600px) {
    .blog-editor {
      padding: 10px;
    }
  }
  