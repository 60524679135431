@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


:root {
  --primary-color: #623b6f;
  --secondary-color: #e0c184;
  --font-family: "Montserrat", sans-serif;
  --purple-1: #311939;
  --purple-2: #52345b;
  --purple-3: #623b6f;
  --purple-4: #754586;
  --purple-5: #8d57a2;
  --purple-6: #a976bf;
  --purple-7: #c39dd5;
  --purple-8: #dac2e5;
  --purple-9: #eaddf1;
  --purple-0: #f4eef9;
  --purple--1: #faf7fc;
  --harvest-gold-50: #fcf9f0;
  --harvest-gold-100: #f7f0dd;
  --harvest-gold-200: #eedeba;
  --harvest-gold-300: #e0c184;
  --harvest-gold-400: #d6a861;
  --harvest-gold-500: #cd9042;
  --harvest-gold-600: #bf7a37;
  --harvest-gold-700: #9f612f;
  --harvest-gold-800: #804e2c;
  --harvest-gold-900: #684126;
  --harvest-gold-950: #372013;
}

body {
  color: #333;
  font-family: var(--font-family);
}

body a {
  text-decoration: none;
  color: #333;
  font-family: var(--font-family);
}

/* .container {
  display: flex;
  align-items: center;
  gap: 20px;
} */

@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

:root {
  --primary-color: #8a5f8c; /* Lightened Purple */
  --secondary-color: #c2a97b; /* Darkened Beige */
  --accent-color: #4a3f5b; /* Complementary Deep Blue */
}