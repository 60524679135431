.calendar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f7f9fc; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.react-calendar {
  border: none; 
  border-radius: 8px; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  font-family: 'Arial', sans-serif;
}

.react-calendar__tile {
  transition: background-color 0.3s ease;
}

.react-calendar__tile:hover {
  background-color: #e0e7ff; 
}

.react-calendar__tile--active {
  background-color: #4f46e5; 
  color: white; 
}

.react-calendar__tile--active:hover {
  background-color: #3730a3; 
}

.booking-marker {
  background-color: #ff6b6b; 
  color: white; 
  padding: 2px 6px; 
  font-size: 12px; 
  text-align: center; 
}

.react-calendar__navigation button {
  background: #ffffff;
  border: none; 
  border-radius: 4px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  color: #4f46e5;
  font-size: 14px;
  padding: 15px 8px;
}

.react-calendar__navigation button:enabled:hover {
  background-color: #f0f4ff; 
}

.react-calendar__navigation button:disabled {
  background-color: #e0e0e0; 
  color: #a0a0a0; 
}

.react-calendar__month-view__weekdays {
  margin: 0; 
  padding: 0; 
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: bold; 
  color: #4f46e5; 
}
