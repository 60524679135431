/* General Styles */
.about {
    padding: 2rem;
    background-color: #f5f5f5; /* Light background color */
  }
  
  .about__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .about__image {
    flex: 1 1 100%;
    max-width: 500px;
  }
  
  .about__image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about__desc {
    flex: 1 1 100%;
    max-width: 500px;
    text-align: center;
  }
  
  .about__title {
    font-size: 1.8rem;
    color: #623b6f;
    margin-bottom: 1rem;
  }
  
  .about__highlight {
    color: #e0c184;
  }
  
  .about__subtitle {
    display: block;
    font-size: 1.2rem;
    color: #623b6f;
    margin-top: 0.5rem;
  }
  
  .about__text {
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .about__lists {
    text-align: center;
  }
  
  .about__list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .about__list li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
    text-align: left;
    position: relative;
    padding-left: 1.5rem;
  }
  
  .about__list li::before {
    content: '✔';
    color: #e0c184;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 1.2rem;
  }
  