.dash-nav-sect{
    background-color: #fff;
    border-bottom: 1px solid #eee;
    height: 70px;
    margin-left: 200px;
    overflow: hidden;
}

.dash-nav-items{
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 30px;
}

.dash-nav-items img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.dash-nav-title{
    margin-right: auto;
}

.dash-nav-title h2{
    font-size: 22px;
    color: #111;
    margin-left: 20px;
}

.logged-user{
    display: flex;
    gap: 25px;
    align-items: center;
    margin-left: auto;
    margin-right: 20px;
}

.logged-user p{
    cursor: pointer;
}

@media(max-width:767px){
    .dash-nav-sect{
        height: 50px;
        margin-left: 30px;
    }
    .dash-nav-title{
        display: none;
    }
    .dash-nav-items img{
        width: 35px;
        height: 35px;
    }
    .logged-user{
        gap: 10px;
    }
}