/* Cart.css */

.cart-item {
    border-radius: 8px;
    padding: 8px;
    margin: auto;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: scroll;
    box-sizing: border-box;
    margin-left: 10px;
    position: relative;
    scrollbar-width: none;
    scrollbar-color: transparent;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
  }

  .cart-item::-webkit-scrollbar {
    display: none;
  }
  
  .cart-item img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .cart-item h3,
  .cart-item p {
    text-align: center;
  }
  
  .cart-item button {
    background-color: #623B6F;
    color: #FFFFFF;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .cart-item .quantity-controls {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .cart-item .description {
    text-align: center;
  }

  .cart-container-items{
    padding: 20px;
    width:60%;
  }

  .cart-container{
    display:flex;
    justify-content:center;
  }

  .cart-container .product-aside{
    background-color:#E0C184;
    flex-grow:1;
    width:40%;
  }
  @media(max-width:767px){
    .cart-container{
      flex-direction: column;
    }

    .cart-container-items{
      width:100%;
    }

    .cart-container .product-aside{
      width:100%;
    }
  }
  @media(min-width:767px){
    .cart-item{
        justify-content: center;
    }
  }