/* CartBook.css */

.cart-book {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
}

.cart-items {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.cart-items-title {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    margin-bottom: 16px;
}

.cart-total {
    font-size: 20px;
    font-weight: 700;
    color: #333;
    margin-top: 16px;
}

.proceed-button {
    width: 100%;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 16px;
    transition: background-color 0.2s ease;
}

.proceed-button:hover {
    background-color: #2980b9;
}

.empty-cart-message {
    font-size: 24px;
    font-weight: 700;
    color: #e74c3c;
    text-align: center;
}
