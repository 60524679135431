.cta {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 60px 0;
    margin: 40px 0;
    overflow: hidden;
    background-image: url("../../../utils/img/Hero/Bg-CTA.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.cta::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
}

.cta > * {
    position: relative;
    z-index: 2;
}

.cta-container{
    width: 70%;
    text-align: center;
}

.cta-container h2{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 15px;
}

.cta-container p{
    line-height: 1.6;
    margin-bottom: 25px;
}

.cta-container button{
    background: none;
    border: none;
    outline: none;
    background-color: var(--primary-color);
    padding: 10px 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    color: #fff;
}

@media(max-width:767px){
    .cta-container{
        width: 100%;
        text-align: center;
    }
}