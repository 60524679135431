/* AddBook.css */
.add-book-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .add-book-container h2 {
    color: #623b6f;
    text-align: center;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #623b6f;
  }
  
  .form-group input[type="text"],
  .form-group input[type="file"],
  .form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  
  .Add-book-form button {
    background-color: #623b6f;
    color: #e0c184;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .Add-book-form button:hover {
    background-color: #4a2e5c;
  }
  