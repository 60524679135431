.create-section {
    background-color: #f4f4f4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.create-section .container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
}

.create-section .button {
    display: inline-block;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border-radius: 8px;
    transition: background-color 0.3s, transform 0.3s;
    width: auto; /* Default width behavior */
}

.create-section .button:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
}

.create-section .button:active {
    background-color: #004494;
    transform: translateY(1px);
}

@media (max-width: 768px) {
    .create-section .button {
        padding: 16px 20px;
        font-size: 18px;
        width: 100%; /* Full width on screens <= 768px */
    }
    .create-section {
        height: 100%;
        margin: 20px;
    }
}

