.products-sect{
    display: flex;
    gap: 35px;
    margin: 30px 60px;
    margin-top: 80px;
}
.products-sect .products-page {
    flex: 0 0 60%;
    
}
.products-sect .product-aside {
    position: static;
    flex: 0 0 40%;
    background-color: var(--secondary-color);
}

.products-page{
    display: flex;
    align-items: center;
    gap: 25px;
    flex-direction: column;
}


.products-page .product-item{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #f0f0f0;
}

.cat-produt-list-item{
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 20px;
}
.cat-produt-list-item img{
    width: 60px;
    height: 60px;
    border-radius: 5px;
}

.cat-produt-list-item p{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    text-align: left;
}

.cat-items{
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
}

.cat-items ul{
    list-style: none;
}
.cat-items ul li{
    padding: 15px;
    list-style: circle;
    margin-left: 20px;
}

.cat-items h4 {
    font-size: 22px;
    font-family: 'Helvetica Neue', Arial, sans-serif; 
    font-weight: 600; 
    color: #2c3e50; 
    text-align: center;
    margin: 0;
    padding: 10px 0;
    position: relative; 
}

.cat-items h4::before {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 60%;
    height: 4px; 
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.2)
    ); 
    border-radius: 2px;
}

.cat-items h4::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -5px;
    transform: translateX(-50%);
    width: 40%;
    height: 2px;
    background-color: #007BFF;
    border-radius: 2px;
}


@media(max-width:767px){
    .products-sect{
        flex-direction: column;
        max-width: 100%;
    }
    .products-page{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .products-page>*{
        width: 80%;
        height: fit-content;
    }
}
@media(max-width:600px){
    .products-sect{
        margin: 30px 10px;
    }
}