.child-header-header{
    width: 70%;
    height: 60px;
    background-color: var(--primary-color);
    justify-content: center;
    align-items: center;
}

.top-item{
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 20px;
    color: #fff;
}

.top-item h3{
    margin: auto;
}

.top-item .top-header-back{
    font-size: 24px;
}

@media(max-width:767px){
    .child-header-header{
        width: 100%;
    }
}