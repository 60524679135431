.side-nav-sect{
    position: fixed;
    left: 0;
    top: 0;
    background-color: #f0f0f0;
    width: 200px;
    height: 100%;
    overflow: hidden;
}

.side-nav-items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.side-nav-items img{
    width: 180px;
}

.main-dash p{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    border-bottom: 2px solid #fff;
    width: 200px;
    padding-bottom: 6px;
}

@media(max-width:767px){
    .main-dash p span{
        display: none;
    }
    .side-nav-sect{
        width: 30px;
        padding: 5px;
    }

    .main-dash p{
        font-size: 14px;
    }
    .side-nav-items img{
        display: none;
    }
}