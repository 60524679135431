nav {
  background-color: #fff;
  height: 80px;
  border-bottom: 2px solid #eee;
  padding: 0 20px;
  transition: all 0.3s ease-in-out; /* Smooth transition for sticky effect */
}

.navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensures the navbar is above other content */
}

.nav-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-logo img {
  width: 160px;
  height: 80px;
}

.nav-lists ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-lists ul li {
  display: inline-block;
}

.nav-lists ul li a {
  font-size: 18px;
  font-weight: 500;
  padding: 10px;
  transition: all 0.2s;
}

.nav-lists ul li a:hover {
  background-color: #ddd;
}

.nav-btns {
  margin-left: auto;
}

.nav-bar-cart{
  position: relative;
}

.nav-bar-cart span{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  left: 0;
  background-color: red;
  color: #fff;
  font-size: 14px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.sm-screen-btn {
  display: flex;
  gap: 15px;
  align-items: center;
}

.humberger {
  font-size: 24px;
  color: #623b6f;
}

.humberger > * {
  margin: 5px;
  cursor: pointer;
}

.book-btn {
  background-color: #64396d;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  padding: 6px 15px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.book-btn:hover {
  background: rgb(169, 118, 191, 0.9);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

@media (min-width: 870px) {
  .humbergerBtn {
    display: none;
  }
}

@media (max-width: 870px) {
  .nav-lists {
    display: none;
    transition: all 0.3s ease-in-out;
  }
  
  .humbergerBtn {
    display: flex;
  }
  
  .humberger {
    display: flex;
  }

  .nav-lists {
    position: fixed;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    background: #fff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transform: translateX(100%);
    transition: transform 0.3s ease; 
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 80px;
    padding-bottom: 30px;
  }

  .nav-lists.show {
    transform: translateX(0);
  }

  .nav-lists ul li {
    display: block;
    padding: 25px;
  }
}

@media (max-width: 450px) {
  .sm-screen-btn {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  nav {
    height: 100px;
    border-bottom: 2px solid #eee;
    padding: 10px;
  }
  
  .nav-lists.show {
    margin-top: 100px;
  }
}
