.footer-area{
    background: var(--primary-color);
}

.footer-items img{
    width: 120px;
}

.footer-row{
    display: flex;
    gap: 25px;
}

.footer-bottom{
    background-color: var(--secondary-color);
    padding: 10px 5px;
}

.footer-bottom-content{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.copyright-menu{
    list-style: none;
}

.footer-row>*{
    width: 33%;
    color: #fff;
    padding: 15px;
}

.footer-row>* ul{
    list-style: none;
}

.footer-row>* ul li{
    color: #fff;
    line-height: 2;
}

.footer-row>* ul li a{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
}

.link svg{
    fill: #fff;
}

.widget-title{
    margin-bottom: 15px;
}

.footer-tag{
    line-height: 1.6;
    margin-bottom: 10px;
}

.social-icons a{
    color: #fff;
    padding: 5px;
    font-size: 24px;
}

@media(max-width:767px){
    .footer-row{
        flex-direction: column;
    }
    .footer-row>*{
        width: 100%;
    }
}