.bestsellers {
    padding: 20px;
    font-family: Arial, sans-serif;
  }

  .bestsellers h2{
    margin-bottom: 25px;
  }
  
  .book-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 20px;
  }
  
  .book-card {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    background: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .book-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .book-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  
  .book-card h3 {
    font-size: 1.2em;
    color: #623b6f; 
    margin: 10px 0;
  }
  
  .price {
    font-weight: bold;
    color: #623b6f; 
    margin: 10px 0;
  }
  
  .add-to-cart {
    background-color: #623b6f; 
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .add-to-cart:hover {
    background-color: #e0c184;
    transform: scale(1.05);
  }